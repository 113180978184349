.box-company-unselected {
  min-height: 55px;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  z-index: 1;
}

.box-company-unselected:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleY(0);
  transform-origin: top center;
  background-color: rgba(255, 133, 83, 0.2);
  z-index: -1;
  border-radius: 10px;
  transition: transform 0.3s;
}

.box-company-unselected:hover::after {
  transform: scaleY(1);
}

.box-company-selected {
  min-height: 55px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  background-color: rgba(255, 133, 83, 0.3);
  border-radius: 10px;
}

.row-projects-style {
  overflow-x: scroll;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 30px;
  min-height: 400px;
}

.col-project-not-selected-style {
  border-radius: 16px;
  min-height: 400px;
}

.text-select-project-style {
  font-size: 16px;
}

.col-projects-style {
  padding: 10px;
  display: inline-block;
  float: none;
}
.col-content-projects-style {
  background: white;
  padding-inline: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  min-height: 400px;
  min-height: 400px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
