.navbar-collapse.in {
  display: block !important;
}

.rounded-box {
  cursor: pointer;
  transform: scaleZ(100);
  transition: transform 300ms;
}
.rounded-box:hover {
  color: black;
  text-underline-offset: 30px;
}

.nav-container {
  padding: 40px;
}
