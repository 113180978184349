.border-skill-background {
  width: 60%;
  min-height: 75vh;
  padding: 0;
  left: 0;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.skill-container {
  width: 100%;
  min-height: 65vh;
}

.row-skills-details-style {
  display: block;
  padding: 30px;
  height: 250px;
}

.row-skills-style {
  overflow-x: scroll;
  display: flex;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  white-space: nowrap;
  padding-inline: 20px;
  border-radius: 16px;
}

.col-skills-style {
  padding-top: 50px;
  padding-inline: 25px;
}
.col-content-skills-style {
  background: white;
  padding: 20px;
  height: 200px;
  width: 200px;
  border-radius: 16px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.img-skill-container-style {
  width: 100%;
  padding: 10px;
  max-height: 100%;
}

.img-skill-style {
  object-fit: scale-down;
  max-height: 100%;
}
