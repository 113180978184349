.bottom-menu-text-style {
  color: black;
}

.social-icon {
  width: 25px;
  margin-left: 20px;
  padding: 0px;
  height: 25px;
  cursor: pointer;
}

.download-cv {
  padding: 10px;
  cursor: pointer;
  background-color: black;
  border-radius: 16px;
  border-color: black;
  stroke-width: 1px;
  color: white;
  text-decoration: none;
}

.download-cv:hover {
  color: red;
}
