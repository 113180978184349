.image-size {
  max-height: auto;
  max-width: 10vh;
}

.info-tab {
  padding: 20px;
  min-height: 20vh;
  min-width: 40vh;
}

.text-format {
  font-family: "DM Sans", sans-serif;
  color: gray;
  font-size: 16px;
  overflow-wrap: break-word;
}

.justify {
  text-justify: inter-word;
}

.inner-top-left {
  border-right: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
}

.inner-top-right {
  border-bottom: 1px solid #d3d3d3;
}

.inner-bottom-left {
  border-right: 1px solid #d3d3d3;
}

.inner-end-bottom-right {
  border-top: 0px solid #d3d3d3;
}

.box {
  padding: 20px;
  transition: box-shadow 0.3s;
}
.box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.about-shadow {
  box-shadow: 0 0 4px rgba(33, 33, 33, 0.2);
}
