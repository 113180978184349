.Home-bg {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.half-screen {
  min-height: 40vh;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.Logo {
  max-width: 30vh;
  max-height: 30vh;
}

.adjust-profileIcon {
  position: relative;
  top: -15vh;
}

.adjust-contactInfo {
  position: relative;
  top: 1vh;
}

.text-header {
  font-family: "DM Sans", sans-serif;
  color: gray;
}

a img:last-child {
  display: none;
}
a:hover img:last-child {
  display: block;
}
a:hover img:first-child {
  display: none;
}
